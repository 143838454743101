<template>
  <el-container>
    <el-container>
      <!-- 中间内容 -->
      <el-main>
        <h2 class="depTitle">科室列表</h2>
        <el-table
          :data="DeplisttableData"
          style="width: 80%; margin: 0 auto"
          border
          center
        >
          <el-table-column type="index" label="序号" width="55" align="center">
          </el-table-column>
          <el-table-column
            prop="name"
            label="科室名称"
            width="180"
            align="center"
          >
          </el-table-column>
          <el-table-column prop="updateDate" label="更新时间" align="center">
          </el-table-column>
          <el-table-column label="填报状态" align="center">
            <template slot-scope="scope">
              <div class="activeStatus" v-if="scope.row.status == 0">√</div>
              <div class="noStatus" v-else>√</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template style="display: flex" slot-scope="scope">
              <el-button
                size="mini"
                type="primary"
                @click="editJixiao(scope.row)"
                ><i class="el-icon-search"></i
              ></el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="pageNum"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="total"
        >
        </el-pagination>
      </el-main>
    </el-container>
    <!-- 点击操作绩效分配结构 -->
    <el-dialog
      title="绩效分配结构"
      :visible.sync="EditdialogVisible"
      width="50%"
    >
      <el-form
        :model="Performance"
        ref="PerformanceRef"
        :rules="PerformanceRules"
        :inline="true"
        label-width="120px"
      >
        <el-form-item label="机构数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="机构数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(个)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item label="编制人数：" prop="name" class="hosform">
          <div class="hosform">
            <el-input v-model="Performance.name"></el-input>
            <div class="hosdanwei">(人)</div>
          </div>
        </el-form-item>
        <el-form-item class="depar-dia">
          <el-button type="primary" @click="editdialogKeshi"
            >提交数据</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      // 绩效分配结构
      Performance: {
        name: "",
      },
      // 绩效分配结构规则
      PerformanceRules: {
        name: [
          {
            required: true,
            message: "请输入绩效分配结构",
            trigger: "blur",
          },
        ],
      },
      // 绩效分配结构弹出框
      EditdialogVisible: false,
      // 表格数据
      DeplisttableData: [],
      // 医院id
      hospitalId: window.sessionStorage.getItem("hospitalId"),
      // 总页数
      total: 1,
      // 每页页数
      pageNum: 1,
      // 每页显示条数
      pageSize: 10,
    };
  },
  created() {
    this.getDepartmentNotPage();
  },
  methods: {
     handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.pageNum = val;
      this.getDepartmentNotPage();
    },
    //   获取医院所属科室列表
    async getDepartmentNotPage() {
      let data = {
        hospitalId: this.hospitalId,
        pageNum: this.pageNum, //页数
        pageSize: this.pageSize, //每页显示数量
      };
      let { data: res } = await this.$axios.getDepartmentList(data);
      // console.log(res);
      if (res.code == 401) {
        this.$router.push("/login");
      } else if (res.code == 500) {
        this.$message({
          message: "获取医院所属科室列表失败",
          type: "error",
        });
      } else if (res.code == 200) {
        this.DeplisttableData = res.rows;
        this.total = res.total;
      }
    },
    editJixiao(row) {
      this.$router.push({
        path: "/depTwo",
        query: { departmentId: row.id },
        meta: {
          activeMenu: "/depTwo", // 高亮
        },
      });
    },
    editdialogKeshi() {},
  },
};
</script>

<style lang="less" scoped>
.hosform {
  display: flex;
  .hosdanwei {
    margin-left: 10px;
  }
}

.activeStatus {
  background: rgb(102, 204, 0);
}
.noStatus,
.activeStatus {
  width: 25px;
  height: 25px;
  color: white;
  line-height: 25px;
  text-align: center;
  margin: 0 auto;
}

.noStatus {
  background: #ccc;
}
.depTitle {
  display: flex;
  width: 90%;
  margin: 0 auto;
  margin-bottom: 30px;
}

.depar-dia {
  width: 100%;
}

/deep/ .el-radio-button__inner {
  width: 100px;
}
.el-pagination {
  margin-top: 20px;
}
</style>
